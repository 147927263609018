export function gidToId (gid = '') {
  if (!gid) return gid

  let pieces = gid.toString().split('/')
  
  return pieces[pieces.length - 1]
}

export const graphQlProductToInventoryItems = (product) => {
  return product.variants.map(variant => {
    const { 
      id: pid,
      title: productTitle,
      images = [],
      hasOnlyDefaultVariant 
    } = product
    const { 
      id: vid,
      title, 
      barcode, 
      sku, 
      image: variantImage,
      inventoryItem,
      price,
      compareAtPrice,
    } = variant


    const image = variantImage?.transformedSrc || images[0]?.originalSrc || '' 

    return { 
      id: gidToId(inventoryItem.id), 
      productTitle, 
      title, 
      hasOnlyDefaultVariant,
      // title: hasOnlyDefaultVariant ? productTitle : title , 
      price, 
      compareAtPrice,
      image,
      barcode, 
      sku,
      pid: gidToId(pid),
      vid: gidToId(vid),
    }
  })
}


export const inventoryItemAndLevels = (item, locations) => {
  const id = gidToId(item.id)
  const { 
    origin,
    destination,
    variant: {
      id: vid,
      sku,
      barcode,
      title,
      image: variantImage,
      product: {
        id: pid,
        title: productTitle,
        featuredImage: productImage,
        hasOnlyDefaultVariant,
      },
    },
  } = item
  const image = variantImage?.transformedSrc || productImage?.transformedSrc || '' 

  const levels = {
    ...(origin && { [locations.origin]: origin.available }),
    ...(destination && { [locations.destination]: destination.available })
  }

  return { 
    item: {
      id, 
      productTitle, 
      title, 
      image,
      barcode, 
      sku,
      hasOnlyDefaultVariant,
      pid: gidToId(pid),
      vid: gidToId(vid),
    },
    levels
  }
}


export const graphQlToInventoryItem = (item) => {
  const id = gidToId(item.id)
  const { 
    unitCost,
    variant: {
      id: vid,
      sku,
      barcode,
      title,
      price,
      compareAtPrice,
      image: variantImage,
      product: {
        id: pid,
        vendor,
        status,
        title: productTitle,
        featuredImage: productImage,
        hasOnlyDefaultVariant,
      },
    },
  } = item
  const image = variantImage?.transformedSrc || productImage?.transformedSrc || '' 

  return { 
      id, 
      productTitle, 
      title, 
      image,
      barcode, 
      sku,
      price,
      status,
      unitCost: unitCost?.amount,
      compareAtPrice,
      vendor,
      hasOnlyDefaultVariant,
      pid: gidToId(pid),
      vid: gidToId(vid),
  }
}


export const graphQlToInventoryItemLevels = (item, locations, currentQuantityName) => {
  const id = gidToId(item.id)
  const { 
    origin,
    destination,
  } = item

  const quantityToUse = currentQuantityName || "on_hand"

  const originQuantity = origin?.quantities 
    ? origin.quantities.filter(q => q.name === quantityToUse)[0].quantity 
    : 0

  const destinationQuantity = destination?.quantities 
      ? destination.quantities.filter(q => q.name === quantityToUse)[0].quantity 
      : 0

  const levels = {
    ...(origin && { [locations.origin]: originQuantity }),
    ...(destination && { [locations.destination]: destinationQuantity })
  }

  return { 
    id,
    levels
  }
}
